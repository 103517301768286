import {reactive, toRefs, defineComponent, onBeforeMount, onMounted, getCurrentInstance, nextTick, watch,computed} from 'vue';
import SplitOrderCardUtil ,{ISplitOrderCardDataObj} from './splitOrderCardUtil';
import {TableColumnCtx} from "element-plus/es/components/table/src/table-column/defaults";
export default defineComponent({
    name:'SplitOrderCard',
    props: {
        //props的这些属性是通过组件的属性传进来的，比如<SplitOrder showFlag/>
        showFlag:{type:Boolean,default: false},//有两个页面要展示拆单，但是显示的东西不同，该字段其实控制不同模块显示不同东西用的
        orderId:{type:String,default: ''},
        childOrderCode:{type:String,default: ''},//当从发运的货代信息列表打开的时候，应该只显示指定的子订单
        //不知道为什么，通过组件传入cardFrom的方式就不行
    },
    title:'拆单',
    modelType:'card',
    fullscreen: true,
    setup(props,context){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:ISplitOrderCardDataObj=reactive<ISplitOrderCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: false,
                modelPath: utils.OrderProviderApi.buildUrl('/formalOrder')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {},
            otherParams:{
                // showFlag:true,//有两个页面要展示拆单，但是显示的东西不同，该字段其实控制不同模块显示不同东西用的
                colName:['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'],
                customerDeliveryData:[],//客户所有收货地址集合
                order:{
                    orderBaseInfo:{
                        code:'',//客户编码
                        name:'',//客户名称
                        countryName:'',//客户国家
                        shrName:'',//收货人名称
                        contact:'',//联系方式
                        xhgk:'',//卸货港口
                        address:'',//收货地址
                        desc:'',//客户备注
                    },
                    code:'',//订单编码
                    num:0,//拆单数量
                    splitInfo:{//拆单信息
                        formatOrderData:[],//订单信息
                        deliveryData:[],//收货地址信息
                        splitDetail:{//拆单详情
                            activeName:'A',//激活的tab
                            data:[]//tab中表格数据
                        }
                    }
                }
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new SplitOrderCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData(proxy);
                if(dataObj.otherParams.order.num==0)dataObj.otherParams.order.num=2;//设置默认拆单数
            })
        })
        //监听拆单数，根据数量进行拆单（构建订单表格数据和收货地址数据）
        watch(() => dataObj.otherParams.order.num,async (newValue,oldValue) => {
            //tmd,正式订单列表查看详情的时候，会包含拆单组件，进入拆单组件会触发这里的watch，所以要判断一下是否清空拆单明细
            if(!props.orderId)dataObj.otherParams.order.splitInfo.splitDetail.data=[];
            dataObj.utilInst.operateSplitOrder();
        })
        //通过FormalOrderDetail包含SplitOrder的时候，会传入orderId，在这里监控orderId由空变为有值的时候去初始化页面，
        //因为这种情况不会进入beforeOpen,只有dialog包含引擎，引擎包含splitOrder的时候才会进入beforeOpen
        watch(() => props.orderId,async (newValue,oldValue) => {
            await dataObj.utilInst.initPage(newValue);
        })
        //---------------------------computed---------------------------

        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            await dataObj.utilInst.initPage(engineInst.engineParams.orderId);
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            dataObj.form.type='splitOrder';
            dataObj.form.splitOrder=dataObj.otherParams.order;
            dataObj.form.orderId=proxy.engine.engineParams.orderId;
            dataObj.form.deliveryAddress=dataObj.otherParams.customerDeliveryData;
            return true;
        }
        //设置参数
        const setParam=(engineInst:any)=>{
            return {orderId:engineInst.engineParams.orderId,cardFrom:engineInst.engineParams.cardFrom};
        }
        //开始拆单按钮事件
        const splitHandler=()=>{
            // console.log(dataObj.otherParams.order.splitInfo.formatOrderData);
            // console.log(dataObj.otherParams.order.splitInfo.deliveryData);
            dataObj.utilInst.doSplitOrder();
        }
        //重置拆单按钮事件
        const resetSplitHandler=()=>{
            dataObj.otherParams.order.splitInfo.splitDetail.activeName='A';
            dataObj.otherParams.order.splitInfo.splitDetail.data=[];
            //tmd，如果只是2的时候，输入了一些值，这个时候点击重置想要清空数据，必须要重新加卡片才可以
            if(dataObj.otherParams.order.num!=2)dataObj.otherParams.order.num=2;
            else proxy.engine.engineUtil.doAddOrLoad('','card');
        }
        const getSummaries=(options:any)=> {
            let total:number=0;
            options.data.forEach((item:any)=>item.jsType==0?total+=item.totalRmb:total+=item.totalUsd)
            return ['总价',total,'','',''];
        }
        //导出excel
        const exportExcelHandler=(type:number)=>{
            dataObj.utilInst.exportExcel(type);
        }
        //格式化属性
        const formatProperty=(row:any, column:TableColumnCtx<any>, value:any, index:number)=>{
            switch (value) {
                case 0:return 'CBU';
                case 1:return 'CKD';
                case 2:return 'SKD';
            }
        }
        //格式化币种
        const formatJsType=(row:any, column:TableColumnCtx<any>, value:any, index:number)=>{
            switch (value) {
                case 0:return '人民币';
                case 1:return '美元';
            }
        }
        return{
            ...toRefs(dataObj),proxy,beforeOpen,beforeSaveHandler,setParam,splitHandler,resetSplitHandler,getSummaries,
            exportExcelHandler,formatProperty,formatJsType
        }
    }
});