import _ from "lodash";
import UtilPub from "@/utils/UtilPub";
import Tools from "@/utils/Tools";
export default class FinanceUtils{
    //选择订单弹出框确认之后，往明细表格追加选择的订单
    public static appendSelOrder(selectRows:any,dataObj:any,gridName:string):void{
        let existRows=dataObj.refMap.get(gridName).getTbInst().tbData;
        let bakData = _.cloneDeep(existRows);
        selectRows.forEach((row:any)=>{
            if(FinanceUtils.checkExist(bakData,row.F_ORDER_CODE)==-1) {
                row.F_PI_ID=row.F_ID;
                delete row.F_STATUS;//OA支付明细有自己的status，所以必须删除，否则传到后台保存的是订单的status
                delete row.F_ID;//删除F_ID，否则addRow的时候辉往更新数组里面添加
                /**
                 * 这里出了一个问题，找了好久才找到这里，问题如下:
                 * 从弹出框选择第n跳记录添加到当前明细表格中，顺序号加入是第1行，问题来了，在当前明细表中，rowIndex本来该为0，结果rowIndex却是n，
                 * 在editTableUtil的77行点击行获取rowindex的时候就会报错，所以就必须把rowIndex删除，删除了之后，rowindex会在
                 * :row-class-name那里添加上，可是遇到一个很怪的问题，我即便这样做了，但是rowindex还是以前的，所以我才用下发深复制一份，这样rowindex就改变了
                 * */
                delete row.rowIndex;
                let tempRow = _.cloneDeep(row);
                dataObj.refMap.get(gridName).addRow(tempRow);
            }
        })
    }
    //往明细添加订单的时候，检查明细表格是否已经存在该订单了
    public static checkExist(existRows:any,addOrder:string):number{
        return existRows.findIndex((item:any)=>item.F_ORDER_CODE==addOrder);
    }
    //根据明细行的付款情况构建可用付款方式下拉数据（这是备份版本，必须先支付预付款再支付尾款的情况）
    public static buildPayTypeData_bak(params:any):any{
        let data;
        if(params.row.F_ID){//修改
            let payType=params.row.F_PAY_TYPE;
            switch (payType) {
                case 0://如果现在是预付款，分两种情况，尾款已支付，那么就只能是预付款了，如果尾款还未支付，那么就可以选预付款和全款
                    params.row.F_WK>0?data=[{value:0,label:'预付款'}]:data=[{value:0,label:'预付款'},{value:2,label:'全款'}];break;
                case 1://如果现在是尾款，那么只能是尾款了，不能由尾款变为预付款和全款
                    data=[{value:1,label:'尾款'}];break;
                case 2://如果现在是全款，那么只能是全款变为预付款，不能由全款变为尾款
                    data=[{value:0,label:'预付款'},{value:2,label:'全款'}];break;
            }
        }else{//新增
            if(params.row.F_YFK==0){//没有付过预付款，那么只能有预付款和全款
                data=[{value:0,label:'预付款'},{value:2,label:'全款'}];
            }else if(params.row.F_WK==0) {//付过预付款，但是没有付过尾款，那么只能有尾款
                data=[{value:1,label:'尾款'}];
            }
        }
        return data;
    }
    //根据明细行的付款情况构建可用付款方式下拉数据,现在可以不用先支付预付款，就可以支付尾款了
    public static buildPayTypeData(params:any):any{
        let data:Array<any>=[];
        if(params.row.F_ID){//修改
            let payType=params.row._F_PAY_TYPE;
            switch (payType) {
                case 0://如果现在是预付款，分两种情况，尾款已支付，那么就只能是预付款了，如果尾款还未支付，那么就可以选预付款、尾款和全款
                    params.row.F_WK>0?data=[{value:0,label:'预付款'}]:data=[{value:0,label:'预付款'},{value:1,label:'尾款'},{value:2,label:'全款'}];break;
                case 1://如果现在是尾款，分两种情况，预付款已支付，那么只能是尾款了，如果预付款还未支付，那么就可以选预付款、尾款和全款
                    params.row.F_YFK>0?data=[{value:1,label:'尾款'}]:data=[{value:0,label:'预付款'},{value:1,label:'尾款'},{value:2,label:'全款'}];break;
                case 2://如果现在是全款，那么就可以选预付款、尾款和全款,因为可以改变为任何一种支付
                    data=[{value:0,label:'预付款'},{value:1,label:'尾款'},{value:2,label:'全款'}];break;
            }
        }else{//新增
            if(params.row.F_YFK==0)data.push({value:0,label:'预付款'});//没有付过预付款
            if(params.row.F_WK==0) data.push({value:1,label:'尾款'});//没有付过尾款
            if(params.row.F_YFK==0 && params.row.F_WK==0)data.push({value:2,label:'全款'});
        }
        return data;
    }
    //计算预付款金额、尾款金额、订单金额
    public static calAmount(params:any):any{
        if('yfk'==params.type){
            params.row.calYfk=UtilPub.toAmountFormat(params.row._F_YFK,false,2);
            return UtilPub.toAmountFormat(params.row._F_YFK,false,2);
        }
        if('wk'==params.type){
            // return UtilPub.toAmountFormat(params.row._F_WK,false,2);
            //为什么尾款不直接用上方从后台返回_F_WK？参看formalOrderListHelper.ts中的calWk方法解释
            let amount=params.row.F_SUM_USD;
            if(params.row.F_JS_TYPE==0)amount=params.row.F_SUM_RMB;
            let yfk=UtilPub.toAmountFormat(params.row._F_YFK,false,2);
            yfk=UtilPub.to_double(yfk);
            params.row.calWk=UtilPub.toAmountFormat(amount-yfk,false,2);
            return UtilPub.toAmountFormat(amount-yfk,false,2);
        }
        if('totalAmount'==params.type){
            return UtilPub.toAmountFormat(params.row.F_AMOUNT,false,2);
        }
    }
    //根据明细表格订单的支付种类，计算需要的总金额
    public static gridOrderTotalAmount(existRows:any):any{
        let totalAmount=0;
        const payType = [0,1,2];
        existRows.forEach((row:any)=>{
            if(payType.includes(row.F_PAY_TYPE)){//有指定付款方式才处理
                // let amount=row.F_JS_TYPE==0?row.F_SUM_RMB:row.F_SUM_USD;
                let amount=row.F_AMOUNT;
                switch (row.F_PAY_TYPE) {//恢复预付款、尾款、全款
                    // case 0:(totalAmount+=row._F_YFK).toFixed(2);break;
                    // case 1:(totalAmount+=row._F_WK).toFixed(2);break;
                    // case 2:(totalAmount+=amount).toFixed(2);break;

                    case 0:
                        // let yfk=UtilPub.toAmountFormat(row._F_YFK,false,2);
                        // yfk=UtilPub.to_double(yfk)
                        // totalAmount+=yfk;break;

                    totalAmount+=UtilPub.to_double(row.calYfk);break;
                    case 1:
                        // let amount2=row.F_SUM_USD;
                        // if(row.F_JS_TYPE==0)amount2=row.F_SUM_RMB;
                        // let yfk2=UtilPub.toAmountFormat(row._F_YFK,false,2);
                        // yfk2=UtilPub.to_double(yfk2)
                        // let wk2=UtilPub.toAmountFormat(amount2-yfk2,false,2);
                        // wk2=UtilPub.to_double(wk2)
                        // totalAmount+=wk2;break;

                        totalAmount+=UtilPub.to_double(row.calWk);break;
                    case 2:(totalAmount+=amount).toFixed(2);break;
                }
            }
        })
        return totalAmount.toFixed(2);
    }
    //保存之前，验证是否选择订单以及订单是否都指定了付款方式
    public static verifySaveData(data:any,proxy:any):boolean{
        //验证明细表格数据是否合法
        if(data.length==0){
            proxy.$message('请选择订单');
            return false;
        }
        const payType = [0,1,2];
        let result=data.findIndex((row:any,index:number)=>{
            if(!payType.includes(row.F_PAY_TYPE)){
                Tools.warning({message:'第'+(index+1)+'行付款类型不能为空'});
            }
            return !payType.includes(row.F_PAY_TYPE)
        })
        return result==-1;
    }
}