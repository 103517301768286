import {reactive, toRefs, defineComponent, onBeforeMount, onMounted, getCurrentInstance, nextTick, watch,computed} from 'vue';
import ProduceCardUtil ,{IProduceCardDataObj} from './produceCardUtil';
export default defineComponent({
    name:'Produce',
    props: {
        //props的这些属性是通过组件的属性传进来的，比如<Produce showFlag :orderId='aaa'/>
        showFlag:{type:Boolean,default: false},//有两个页面要展示拆单，但是显示的东西不同，该字段其实控制不同模块显示不同东西用的
        orderId:{type:String,default: ''}
    },
    title:'维护生产信息',
    modelType:'card',
    fullscreen: true,
    setup(props,context){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IProduceCardDataObj=reactive<IProduceCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: false,
                modelPath: utils.OrderProviderApi.buildUrl('/formalOrder')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {},
            otherParams:{
                map:new Map(),//把子订单放一份到map集合中，当从组中删除子订单的时候，好从map中找到完整的子订单追加到子订单下拉数据集中，方便再次添加到其他分组中
                selectOrders:[],//选择的子订单号
                childOrders:[],//子订单下拉数据集
                groupDialog:{//设置分组弹出框
                    visible:false,
                    type:0,//新增分组还是选择分组
                    value1:'',//选择所属分组
                    value2:''//创建的分组名称
                },
                scjdData:[{value:0,label:'含谷基地'},{value:1,label:'涪陵基地'}],//生产基地
                activeTab:0,//选中的分组
                orderSplitFlag:2,//订单是否分组1-不分组 2-分组，只会是1和2
                groupInfo:[]//已经创建出来的分组信息
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ProduceCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData(proxy);
            })
        })
        //---------------------------computed---------------------------
        //WC,这种提示是第一次遇到，没办法，就是这么特别
        const delMsg=computed(()=>{
            return (tbData:Array<any>) => {
                if(tbData.length>1){
                    return '确定从当前分组中删除该订单号？';
                }else{
                    return '确定删除最后一个订单号？确定后，该分组将被一并删除。';
                }
            };
        })
        //通过FormalOrderDetail包含Produce的时候，会传入orderId，在这里监控orderId由空变为有值的时候去初始化页面，
        //因为这种情况不会进入beforeOpen,只有dialog包含引擎，引擎包含Produce的时候才会进入beforeOpen
        watch(() => props.orderId,async (newValue,oldValue) => {
            await dataObj.utilInst.initPage(newValue);
        })
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            await dataObj.utilInst.initPage(proxy.engine.engineParams.orderId);
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            if(dataObj.otherParams.groupInfo.length==0){
                proxy.$message('没有任何分组');
                return false;
            }
            let map:any=new Map();
            for(let i=0;i<dataObj.otherParams.groupInfo.length;i++){
                let index=dataObj.otherParams.groupInfo[i].tbData.findIndex((item:any)=>item.scjhh=='');
                if(index!=-1){
                    proxy.$message({type:'warning',message:dataObj.otherParams.groupInfo[i].title+'的生产计划号表第'+(index+1)+'行不能为空'});
                    dataObj.otherParams.activeTab=i;
                    return false;
                }
                //前端已填的生产计划号是否存在重复
                let tbData=dataObj.otherParams.groupInfo[i].tbData;
                for(let j=0;j<tbData.length;j++){
                    if(map.get(tbData[j].scjhh)){
                        proxy.$message({type:'warning',message:dataObj.otherParams.groupInfo[i].title+'的生产计划号表中【'+tbData[j].scjhh+'】存在重复'});
                        dataObj.otherParams.activeTab=i;
                        return false;
                    }else{
                        map.set(tbData[j].scjhh,tbData[j].scjhh);
                    }
                }
            }
            dataObj.form.type='groupOrder';
            dataObj.form.groupOrder=dataObj.otherParams.groupInfo;
            dataObj.form.orderId=proxy.engine.engineParams.orderId;
            // console.log(dataObj.otherParams)
            return true;
        }
        //设置参数
        const setParam=(engineInst:any)=>{
            return {orderId:engineInst.engineParams.orderId,cardFrom:engineInst.engineParams.cardFrom};
        }
        //创建分组
        const groupHandler=()=>{
            if(dataObj.otherParams.selectOrders && dataObj.otherParams.selectOrders.length>0){
                dataObj.otherParams.groupDialog.value1='';//重置弹出框属性值
                dataObj.otherParams.groupDialog.value2='';
                dataObj.otherParams.groupDialog.visible=true;
            }else{
                proxy.$message({type:'warning',message:'请选择订单号'});
            }
        }
        //分组确定事件
        const groupSureHandler=()=>{
            if(dataObj.otherParams.groupDialog.type==0){//选择已有分组
                if(!dataObj.otherParams.groupDialog.value1){
                    proxy.$message({type:'warning',message:'请选择一个分组'});
                    return;
                }else{
                    dataObj.utilInst.joinExistGroup();//加入已有分组
                }
            }else{//创建新的分组
                if(!dataObj.otherParams.groupDialog.value2){
                    proxy.$message({type:'warning',message:'请输入要创建的分组名称'});
                    return;
                }else{
                    dataObj.utilInst.joinNewGroup();//创建新的分组
                }
            }
        }
        //删除生产计划号表格数据某行事件
        const handleDelete=(index:number,row:any,title:string)=>{
            dataObj.otherParams.groupInfo.find((item:any)=>{
                if(item.title===title){
                    item.sumNum-=row.sumNum;//让该tab的订单总数量减少
                    item.tbData.splice(index,1);//删除改行记录
                    if(item.sjrksl>item.sumNum)item.sjrksl=item.sumNum;//如果此刻的实际入库数量大于了订单的总数量，需要调整数量
                    //如果该tab要被删除，那么就让第一格tab处于激活状态，否则不改变tab的激活状态，仍然停留在当前tab
                    if(item.tbData.length==0)dataObj.otherParams.activeTab=0;
                }
            });
            //删除生产计划之后，如果生产计划表为空了，那么把整个tab也给干掉
            dataObj.otherParams.groupInfo=dataObj.otherParams.groupInfo.filter((item:any)=>item.tbData.length>0);
            //把删掉的生产计划（子订单）归还到子订单下拉数据集中
            dataObj.otherParams.childOrders.push(dataObj.otherParams.map.get(row.orderCode));
        }
        const getChildSum=(param: any)=>{
            const { columns, data } = param;
            let sum=0;
            data.forEach((item:any) =>sum+=item.splitNum)
            return ['合计','','',sum];
        }
        return{
            ...toRefs(dataObj),proxy,delMsg,beforeOpen,beforeSaveHandler,setParam,groupHandler,groupSureHandler,handleDelete,
            getChildSum
        }
    }
});