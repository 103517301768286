import BaseBean from "@/utils/BaseBean";

export interface ISplitOrderCardDataObj {
    utilInst:SplitOrderCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class SplitOrderCardUtil extends BaseBean{
    public dataObj:ISplitOrderCardDataObj;

    constructor(proxy:any,dataObj:ISplitOrderCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData(proxy:any):Promise<void>{

    }
    //查询订单信息
    public async initPage(id:string):Promise<any>{
        let res=await this.utils.OrderProviderApi.orderInfo({params:{id:id}});
        if(res.result){
            if(res.flag){//如果已经保存过了，那么就用数据库里面已经保存过的内容
                this.dataObj.otherParams.customerDeliveryData=JSON.parse(res.splitOrderBean.deliveryAddress);
                this.dataObj.otherParams.order=JSON.parse(res.splitOrderBean.content);
                console.log(this.dataObj.otherParams.order)
            }else{//没有保存过，那么根据后台返回内容构建订单基础信息和客户收货地址
                this.dataObj.otherParams.order.orderBaseInfo={
                    code:res.customerBean.code,
                    name:res.customerBean.name,
                    countryName:res.customerBean.countryName,
                    shrName:res.customerDeliveryBean.name,
                    contact:res.customerDeliveryBean.contact,
                    xhgk:res.customerDeliveryBean.xhgk,
                    address:res.customerDeliveryBean.address,
                    desc:res.orderBean.desc
                }

                this.dataObj.otherParams.order.code=res.orderBean.code.substr(2);//订单编码
                this.dataObj.otherParams.customerDeliveryData=[];//以防点击重置的时候重复往里面加数据
                res.customerDeliveryData.forEach((item:any,index:number)=>{//构造收货地址下拉数据
                    this.dataObj.otherParams.customerDeliveryData.push({value:item.id,label:item.address,item:item,disabled:false});
                })
                this.dataObj.otherParams.order.splitInfo.formatOrderData=res.formatOrderData;//后台构建的订单信息
                if(this.dataObj.otherParams.order.num==0)this.dataObj.otherParams.order.num=2;//设置默认拆单数
                this.operateSplitOrder();//根据拆单数进行拆单
            }
        }
        if(this.proxy.childOrderCode){//传入子订单号，表示只显示该子订单号，其它不显示，比如发运列表打开查看详情的时候就会传入
            this.dataObj.otherParams.order.splitInfo.splitDetail.activeName=this.proxy.childOrderCode.split('-')[1]
            this.dataObj.otherParams.order.splitInfo.splitDetail.data=this.dataObj.otherParams.order.splitInfo.splitDetail.data.filter((item:any)=>item.title==this.proxy.childOrderCode)
        }
    }
    //操作分单
    public operateSplitOrder():void{
        //循环订单表格行，根据拆单数为每行追加指定列数
        this.dataObj.otherParams.order.splitInfo.formatOrderData.forEach((item:any)=>this.appendSplitOrderCols(item));
        //构建收货地址表格数据
        this.buildDeliveryData();
        // this.dataObj.otherParams.order.splitInfo.splitDetail.data=[];
    }
    //为订单表格每行追加指定数目的列
    public appendSplitOrderCols(item:any):void{
        for(let i=0;i<this.dataObj.otherParams.order.num;i++){
            this.addObjProp(item,this.dataObj.otherParams.colName[i],0);
        }
    }
    //为收货地址表格构建数据（只有一行数据）
    public buildDeliveryData():void{
        this.dataObj.otherParams.order.splitInfo.deliveryData=[];//清空表格数据，其实只有一行
        let deliveryRowData:any={};//{A:'',B'',......}
        for(let i=0;i<this.dataObj.otherParams.order.num;i++){
            this.addObjProp(deliveryRowData,this.dataObj.otherParams.colName[i]);
        }
        this.dataObj.otherParams.order.splitInfo.deliveryData.push(deliveryRowData);
    }

    //开始拆单按钮处理
    public doSplitOrder():void{
        //验证收货地址是否都选择了
        let delivery=this.dataObj.otherParams.order.splitInfo.deliveryData[0];
        for(let j=0;j<this.dataObj.otherParams.order.num;j++){
            if(!delivery[this.dataObj.otherParams.colName[j]]){
                this.proxy.$message({type:'warning',message:this.dataObj.otherParams.colName[j]+'收货地址不能为空'});
                return;
            }
        }
        //验证订单表格追加列中分单数量相加是否等于订单本来的数量
        let formatOrderData=this.dataObj.otherParams.order.splitInfo.formatOrderData;
        for(let i=0;i<formatOrderData.length;i++){
            let splitTotalNum:number=0;
            for(let j=0;j<this.dataObj.otherParams.order.num;j++){
                splitTotalNum+=formatOrderData[i][this.dataObj.otherParams.colName[j]];
            }
            if(formatOrderData[i].num!=splitTotalNum){
                this.proxy.$message({type:'warning',message:'第'+(i+1)+'行拆单数量有误'});
                return;
            }
        }
        this.genSplitOrderTabs();
    }
    //生成拆单明细tab
    public genSplitOrderTabs():void{
        let formatOrderData=this.dataObj.otherParams.order.splitInfo.formatOrderData;
        this.dataObj.otherParams.order.splitInfo.splitDetail.data=[];//清空tab
        let deliveryAddress=this.dataObj.otherParams.order.splitInfo.deliveryData[0];//所有的收货地址
        for(let i=0;i<this.dataObj.otherParams.order.num;i++){
            let tbData:any=[];//单个tab的表格数据
            let deliveryDetail=this.getDeliveryDetailByValue(deliveryAddress[this.dataObj.otherParams.colName[i]]);//找到收货地址详细信息
            //循环订单表格数据，为表格每行数据里面追加一些tab要显示的字段
            formatOrderData.forEach((item:any)=>{
                let tableItemRow={};
                let splitNum:number=item[this.dataObj.otherParams.colName[i]];//找到对应列的拆单数量
                //修改结算类型、根据结算类型找到结算单价、拆单数量、收货地址详细信息（收货地址ID也存下来吧，虽然重复了）
                Object.assign(tableItemRow,item,deliveryDetail.item,{
                        jsType:0==item.jsType?'人民币':'美元',_price:0==item.jsType?item.price:item.priceUsd,splitNum:splitNum,
                        property:this.formatProperty(item.property),customerDeliveryId:deliveryDetail.value
                    });
                tbData.push(tableItemRow);
            })
            let title=this.dataObj.otherParams.order.code+'-'+this.dataObj.otherParams.colName[i];//tab的title
            this.dataObj.otherParams.order.splitInfo.splitDetail.data.push({title:title,tbData:tbData,name:this.dataObj.otherParams.colName[i]});
            this.dataObj.otherParams.order.splitInfo.splitDetail.activeName='A';
        }
    }
    //根据收货地址value找到收货地址详细信息
    public getDeliveryDetailByValue(value:string):any{
        return this.dataObj.otherParams.customerDeliveryData.find((item:any)=>item.value===value);
    }
    //为对象动态添加属性和值
    public addObjProp(obj:any,propName:string,propValue:any=''):any{
        Object.defineProperty(obj, propName, {
            value: propValue,
            writable: true,
            enumerable: true,
            configurable: true
        });
    }
    //格式化车型公告号配置明细属性
    public formatProperty(property:number):string{
        let result='';
        switch (property) {
            case 0:result='CBU';break;
            case 1:result='CKD';break;
            case 2:result='SKD';break;
        }
        return result;
    }
    //导出
    public exportExcel(type:number):void{
        // console.log(this.dataObj.otherParams.order);
        if(type==1){
            let labels=['品牌','车型','车型公告号','成品码','属性','颜色','数量','单价','币种']
            let data:any=[];
            let total:number=0;
            this.dataObj.otherParams.order.splitInfo.formatOrderData.forEach((item:any)=>{
                let price=item.jsType==0?item.price:item.priceUsd;
                let jsType=item.jsType==0?'人民币':'美元';
                item.jsType==0?total+=item.totalRmb:total+=item.totalUsd;
                data.push([item.brandName,item.cxName,item.cxggh,item.cpm,this.formatProperty(item.property),item.color,item.num,price,jsType]);
            })
            data.push(['总价',total,'','','','','','','']);
            this.proxy.excelUtils(labels, data, '订单'+this.dataObj.otherParams.order.code+'明细');
        }else{
            let labels=['子订单号','品牌','车型','车型公告号','成品码','颜色','数量','单价','币种','属性','收货人名称','联系电话','收货地址']
            let data:any=[];
            let cols:Array<string>=[];
            this.dataObj.otherParams.order.splitInfo.splitDetail.data.forEach((item1:any,index:number)=>{
                cols[index]=item1.name;
                item1.tbData.forEach((item2:any)=>{
                    data.push([item1.title,item2.brandName,item2.cxName,item2.cxggh,item2.cpm,item2.color,item2.splitNum,item2._price,item2.jsType,item2.property,item2.name,item2.contact,item2.address])
                })
            })
            let _title=cols[0];
            if(cols.length>1)_title=_title+'-'+cols[cols.length-1];
            this.proxy.excelUtils(labels, data, '订单'+this.dataObj.otherParams.order.code+'['+_title+']明细');
        }
    }
}