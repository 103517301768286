import {ref, reactive, toRefs, computed,watch,defineComponent,onBeforeMount,onMounted,getCurrentInstance,provide,nextTick} from 'vue';
import XyzJdCardUtil,{IXyzJdCardDataObj} from './xyzJdCardUtil';
import XyzCard from "@/views/project/order/finance/xyz/xyz/XyzCard.vue";
export default defineComponent({
    name:'XyzJdCard',
    title:'信用证交单',
    modelType:'card',
    fullscreen: false,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IXyzJdCardDataObj=reactive<IXyzJdCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: false,
                modelPath: utils.OrderProviderApi.buildUrl('/xyzJd')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                mainId: [utils.UtilPub.commonValidRule(proxy.$t('xyzJd.form.mainId_rule'))],
                orderId: [utils.UtilPub.commonValidRule(proxy.$t('xyzJd.form.orderId_rule'))],
                date: [utils.UtilPub.commonValidRule(proxy.$t('xyzJd.form.date_rule'))],
                amount: [utils.UtilPub.commonValidRule(proxy.$t('xyzJd.form.amount_rule'))]
            },
            otherParams:{
                disableMainId:false,//编辑的时候，不能修改信用证号
                xyzData:[],//可交单的信用证集合
                orderData:[],//某个信用证下的订单集合
                activeName:'curJd',//tab当前激活页签
                finishJdList:[],//已完成的交单
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new XyzJdCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'mainId'==params.comboId){
                    return dataObj.otherParams.xyzData
                }
                if(params && 'orderId'==params.comboId){
                    return dataObj.otherParams.orderData
                }
            }
        })
        //格式化已完成交单的交单项金额
        const jdAmount=computed(()=>{
            return (params:any) => {
                return utils.UtilPub.toAmountFormat(params.amount,false,2)
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=async (newValue:any,selectId:string)=>{
            if('mainId'==selectId){
                dataObj.form.orderId='';
                dataObj.form.amount=0;
                if(newValue)await dataObj.utilInst.buildOrderData(newValue);
            }
            if('orderId'==selectId){
                dataObj.form.amount=0;
                if(newValue)dataObj.utilInst.calSelectOrderAmount(newValue);
            }
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            if('/load'==addOrLoad){
                await dataObj.utilInst.buildOrderData(res.data.mainId);
                dataObj.form.orderId=JSON.parse(res.data.orderId);
                dataObj.otherParams.finishJdList=res.data.finishJdList;
                if(res.status>=utils.Const.xyz_status_11)dataObj.disabled=true;
                dataObj.otherParams.disableMainId=true;
            }
        }
        //构造参数传给后台
        const setParam=(engineInst:any)=>{
            return {cardFrom:engineInst.engineParams.cardFrom};
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }
        //保存验证表单不通过钩子
        const afterErrorValid=(inst:any)=>{
            dataObj.otherParams.activeName='curJd';
        }
        //弹出框查看信用证卡片
        const openXyzCard=async ()=>{
            await utils.UtilPub.openDialog({proxy:proxy,addOrEdit:'edit',id:dataObj.form.mainId,modelComp:XyzCard,ownerComp:proxy,cardFrom:'xyzJdCard',
                title:'信用证详情',contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true})
        }
        return{
            ...toRefs(dataObj),proxy,comboSelect,jdAmount,selectOnChange,beforeOpen,setParam,beforeSaveHandler,afterErrorValid,openXyzCard
         
        }
    }
});