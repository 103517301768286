import BaseBean from "@/utils/BaseBean";
import SplitOrder from "@/views/project/order/order/formalOrder/splitOrder/SplitOrder.vue";

export interface IFormalOrderListDataObj {
    utilInst:FormalOrderListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class FormalOrderListUtil extends BaseBean{
    public dataObj:IFormalOrderListDataObj

    constructor(proxy:any,dataObj:IFormalOrderListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //得到的选择行
    public getSelectedRow():any{
        let selectRows = this.dataObj.pageListRef.getTbInst().getSelection();
        if (selectRows.length == 0) {
            this.proxy.$message(this.proxy.$t('请选择要操作的行'));
            return;
        }
        return selectRows;
    }
    //处理拆单事件
    public async doSplitHandler(selectRows:any):Promise<any>{
        this.utils.Tools.configBox({
            message:'是否需要拆分订单',
            confirmButtonText:'是,进入操作页面',
            cancelButtonText:'否,无需拆分',
            distinguishCancelAndClose:true,
            showCancelMsg:false,
            sureFn:async ()=> {
                await this.utils.UtilPub.openDialog({id:'',proxy:this.proxy,addOrEdit:'add',modelComp:SplitOrder,ownerComp:this.dataObj.pageListRef,
                    orderId:selectRows[0].F_ORDER_ID,
                    cardFrom:'FormalOrderSplit',overflow:'hidden',title: '拆单',contentHeight:'',showFooterBtn:false,fullscreen:true})
            },
            cancelFn:async ()=> {
                let res=await this.utils.OrderProviderApi.notSplit({params:{id:selectRows[0].F_ORDER_ID}});
                if(res.result){
                    this.utils.Tools.success();
                    this.proxy.pageListRef.queryHandler();
                }
            },
            closeFn:async ()=>{
                this.utils.Tools.ctx.$message({type: 'info',message: this.utils.Tools.ctx.$t('tools.cancelOperate')});
            }
        });
    }
    //点击某行，打开分配单证专员弹出框，这里是初始化弹出框中的数据
    public async initDzzyDialog(selectRows:any):Promise<void>{
        this.dataObj.otherParams.fpdzzy.visible=true;
        //重置弹出框中数据
        this.dataObj.otherParams.fpdzzy.dzzy='';
        this.dataObj.otherParams.fpdzzy.selectOrders=[];
        this.dataObj.otherParams.fpdzzy.childOrders=[];
        //构造该订单的子订单下拉数据集
        const loading = this.proxy.$loading({lock: true,text: '请等候......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
        let res=await this.utils.OrderProviderApi.getChildOrdersByOrderId({params:{id:selectRows[0].F_ORDER_ID}});
        if(res.result){
            if(res.childOrders){//订单已拆分
                //已经指定了订单专员的子订单则不可用，其他的子订单可用
                this.dataObj.otherParams.fpdzzy.childOrders=res.childOrders.map((item:any)=>{
                    return {value:item.id,label:item.splitOrderCode,splitFlag:2,item:item,disabled:item.userId?true:false}
                })
            }else{//订单不拆分
                let orderItem={value:res.orderBean.id,label:res.orderBean.code.substr(2),splitFlag:1,item:res.orderBean,disabled:res.orderBean.userId?true:false}
                this.dataObj.otherParams.fpdzzy.childOrders.push(orderItem);
            }
        }
        loading.close();
    }
    //分配单证专员确认事件
    public async doFpdzzy():Promise<any>{
        let selectOrders=this.dataObj.otherParams.fpdzzy.selectOrders;
        let dzzy=this.dataObj.otherParams.fpdzzy.dzzy;
        if(!selectOrders || selectOrders.length==0 || !dzzy){
            this.proxy.$message({type:'warning',message:'请选择订单号和单证专员'});
        }else{
            //如果是不允许拆单，那么selectOrders就只会有一项，且里面放的是订单，否则里面放的是子订单集合,后台通过该值进行判断是更新订单还是子订单
            let splitFlag=selectOrders[0].splitFlag;
            let params={splitFlag:splitFlag,dzzy:this.dataObj.otherParams.fpdzzy.dzzy,childOrderId:selectOrders.map((item:any)=>item.value)};
            const loading = this.proxy.$loading({lock: true,text: '请等候......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
            let res=await this.utils.OrderProviderApi.fpddzy({params:params});
            if(res.result){
                this.utils.Tools.success();
                this.proxy.pageListRef.queryHandler();
                this.dataObj.otherParams.fpdzzy.visible=false;
            }
            loading.close();
        }
    }
    //单证专员下拉选择改变事件，改变子订单下拉数据集，选择上已经分配给该单证专员的子订单
    public dzzySelChange(val:string,that:any):void{
        this.dataObj.otherParams.fpdzzy.selectOrders=[];
        if(val){
            this.dataObj.otherParams.fpdzzy.childOrders.forEach((item:any)=>{
                if(item.item.userId==val)this.dataObj.otherParams.fpdzzy.selectOrders.push(item);
            })
        }
    }
}