import BaseBean from "@/utils/BaseBean";
import HtxxdCard from "@/views/project/order/fy/zl/bgzl/htxxd/HtxxdCard.vue";
import XdxxdCard from "@/views/project/order/fy/zl/bgzl/xdxxd/XdxxdCard.vue";
import FpxxdCard from "@/views/project/order/fy/zl/bgzl/fpxxd/FpxxdCard.vue";

export interface IDzzlCardDataObj {
    utilInst:DzzlCardUtil
    disabled:boolean
    refMap:Map<string,any>
    otherParams:any
}
export default class DzzlCardUtil extends BaseBean{
    public dataObj:IDzzlCardDataObj;
    public props:any;

    constructor(proxy:any,dataObj:IDzzlCardDataObj,props:any) {
        super(proxy);
        this.dataObj=dataObj
        this.props=props
    }
    public initBgzlInfo():any{
        //先清空
        this.dataObj.otherParams.bgzlInfo={
            htxxd:[],
            xdxxd:[],
            fpxxd:[]
        }
        this.props.myParams.bgzlBean.list.forEach((item:any)=>{
            let flag:boolean=false;
            switch (item.type) {
                case 0://合同信息单
                    if(this.props.myParams.bgzlBean.yxht.indexOf(item.id)!=-1)flag=true;
                    let index0=this.dataObj.otherParams.bgzlInfo.htxxd.length+1;
                    this.dataObj.otherParams.bgzlInfo.htxxd.push({id:item.id,name:'报关合同-'+index0+'.pdf',flag: flag});
                    break;
                case 1://箱单信息单
                    if(this.props.myParams.bgzlBean.yxxd.indexOf(item.id)!=-1)flag=true;
                    let index1=this.dataObj.otherParams.bgzlInfo.xdxxd.length+1;
                    this.dataObj.otherParams.bgzlInfo.xdxxd.push({id:item.id,name:'箱单-'+index1+'.pdf',flag: flag});
                    break;
                case 2://发票信息单
                    if(this.props.myParams.bgzlBean.yxfp.indexOf(item.id)!=-1)flag=true;
                    let index2=this.dataObj.otherParams.bgzlInfo.fpxxd.length+1;
                    this.dataObj.otherParams.bgzlInfo.fpxxd.push({id:item.id,name:'发票-'+index2+'.pdf',flag: flag});
                    break;
            }
        })
    }
    //打开合同信息单、箱单信息单、发票信息单的pdf
    public async openPdf(id:string,childOrderId:string,type:number,title:string):Promise<any>{
        let modelComp:any=HtxxdCard;
        if(type==1){
            modelComp=XdxxdCard;
        }else if(type==2){
            modelComp=FpxxdCard;
        }
        await this.utils.UtilPub.openDialog({id:id,proxy:this.proxy,addOrEdit:'/load',modelComp:modelComp,ownerComp:this.proxy,
            type:type,title: title,childOrderId:childOrderId,
            cardFrom:'DzzlCard',overflow:'hidden',contentHeight:'',showFooterBtn:false,fullscreen:true})
    }
}