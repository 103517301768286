import {reactive, toRefs,defineComponent,onBeforeMount,onMounted,getCurrentInstance,nextTick} from 'vue';
import XdxxdCardUtil ,{IXdxxdCardDataObj} from './xdxxdCardUtil';
import html2pdf from 'html2pdf.js';
import logo001 from '@/assets/order/logo001.png';
import dcwts from '@/assets/order/dcwts.png';
export default defineComponent({
    name:'XdxxdCard',
    title:'箱单信息单',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IXdxxdCardDataObj=reactive<IXdxxdCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: false,
                modelPath: utils.OrderProviderApi.buildUrl('/bgzlContent')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {},
            otherParams:{
                dcwtsPng:dcwts,//图片
                logo001:logo001,//图片
                totalNum:0,//总数量
                totalMz:0,//总毛重
                totalJz:0,//总净重
                totalTj:0,//总体积
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new XdxxdCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            res.data.list.forEach((item:any)=>{
                dataObj.otherParams.totalNum+=item.num;
                dataObj.otherParams.totalMz+=item.mz;
                dataObj.otherParams.totalJz+=item.jz;
                dataObj.otherParams.totalTj+=item.tj;
            })
            utils.UtilPub.toAmountFormat(dataObj.otherParams.totalMz,false,2);
            utils.UtilPub.toAmountFormat(dataObj.otherParams.totalJz,false,2);
            utils.UtilPub.toAmountFormat(dataObj.otherParams.totalTj,false,2);
        }
        //加载pi参数
        const setParam=(engineInst:any)=>{
            return {cardFrom:'XdxxdCard'};
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }

        const printPdf=()=>{
            let dialogContent:any = (document.getElementById('myContent') as any).innerHTML;
            let printWindow :any= window.open('', '_blank');
            printWindow.document.open();
            printWindow.document.write("<html><head><title>"+dataObj.form.bghthm+"</title></head><body>");
            printWindow.document.write(dialogContent);
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            printWindow.print();
        }
        const downloadPdf=()=>{
            const element = dataObj.refMap.get('myContent'); // 通过 ref 获取要转换为 PDF 的元素
            (html2pdf as any)().from(element).save(dataObj.form.bghthm+'_箱单信息单.pdf'); // 将元素转换为 PDF 并保存为文件
        }
        return{
            ...toRefs(dataObj),proxy,beforeOpen,setParam,beforeSaveHandler
            ,printPdf,downloadPdf
        }
    }
});