export default {
    zh:{
        xyzYhrz:{
            queryParam:{
                mainId:'信用证号',
                mainId_placeholder:'请输入信用证号',
                date:'承兑及收汇日期',
                date_placeholder:'请选择承兑及收汇日期',
            },
            columns:{
                F_MAIN_ID:'信用证号',
                F_DATE:'承兑及收汇日期',
                F_AMOUNT:'实际收汇金额',
                F_SXF:'手续费',
                SYS_ADD_USER:'添加人',
            },
            form:{
                mainId:'信用证',
                mainId_placeholder:'请选择信用证',
                mainId_rule: '请选择信用证',
                date:'承兑及收汇日期',
                date_placeholder:'请选择承兑及收汇日期',
                date_rule: '请选择承兑及收汇日期',
                amount:'实际收汇金额',
                amount_rule: '请输入实际收汇金额',
                sxf:'手续费',
            },
        }
    },
    en:{
        xyzYhrz:{
            queryParam:{
                mainId:'信用证号',
                mainId_placeholder:'请输入信用证号',
                date:'承兑及收汇日期',
                date_placeholder:'请选择承兑及收汇日期',
            },
            columns:{
                F_MAIN_ID:'信用证号',
                F_DATE:'承兑及收汇日期',
                F_AMOUNT:'实际收汇金额',
                F_SXF:'手续费',
                SYS_ADD_USER:'添加人',
            },
            form:{
                mainId:'信用证',
                mainId_placeholder:'请选择信用证',
                mainId_rule: '请选择信用证',
                date:'承兑及收汇日期',
                date_placeholder:'请选择承兑及收汇日期',
                date_rule: '请选择承兑及收汇日期',
                amount:'实际收汇金额',
                amount_rule: '请选择实际收汇金额',
                sxf:'手续费',
            },
        }
    }
}