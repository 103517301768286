import BaseBean from "@/utils/BaseBean";

export interface IXyzJdCardDataObj {
    utilInst:XyzJdCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class XyzJdCardUtil extends BaseBean{
    public dataObj:IXyzJdCardDataObj;

    constructor(proxy:any,dataObj:IXyzJdCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //查询可交单的信用证集合
        this.dataObj.otherParams.xyzData=await this.utils.OrderProviderApi.xyzJdData({});
    }
    //构建某个信用证下的订单集合
    public async buildOrderData(xyzId:string):Promise<any>{
        this.dataObj.otherParams.orderData=await this.utils.OrderProviderApi.xyzOrderData({params:{xyzId:xyzId}});
    }
    //计算所选订单的金额
    public calSelectOrderAmount(selOrders:Array<string>):void{
        selOrders.forEach((orderId:string)=>{
            let item=this.dataObj.otherParams.orderData.find((orderItem:any)=>orderItem.value==orderId);
            if(item){
                this.dataObj.form.amount=parseFloat(this.dataObj.form.amount);//toFixed会把amount变成字符串，所以下面相加之前先转一下
                this.dataObj.form.amount+=item.item.amount;
                this.dataObj.form.amount=this.dataObj.form.amount.toFixed(2)
            }
        })
    }
}