import {ref, reactive, toRefs, computed,watch,defineComponent,onBeforeMount,onMounted,getCurrentInstance,provide,nextTick} from 'vue';
import FinanceUtils from "@/views/project/order/finance/financeUtils";
import XyzCardUtil,{IXyzCardDataObj} from './xyzCardUtil';
import FormalOrderList from "@/views/project/order/order/formalOrder/FormalOrderList.vue";
import PiCard from "@/views/project/order/order/pi/PiCard.vue";
import XyzJdCard from "@/views/project/order/finance/xyz/jd/XyzJdCard.vue";

export default defineComponent({
    name:'XyzCard',
    title:'信用证',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('EditTableOwner', proxy);
        let dataObj:IXyzCardDataObj=reactive<IXyzCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: true,
                detailInfos:{
                    xyzDetail:{},
                },
                details:['xyzDetail'],
                modelPath: utils.OrderProviderApi.buildUrl('/xyz')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                code: [utils.UtilPub.commonValidRule(proxy.$t('xyz.form.code_rule'))],
                bz: [utils.UtilPub.commonValidRule(proxy.$t('xyz.form.bz_rule'))],
                kzrq: [utils.UtilPub.commonValidRule(proxy.$t('xyz.form.kzrq_rule'))],
                yxq: [utils.UtilPub.commonValidRule(proxy.$t('xyz.form.yxq_rule'))],
                zczq: [utils.UtilPub.commonValidRule(proxy.$t('xyz.form.zczq_rule'))],
                zcjdr: [utils.UtilPub.commonValidRule(proxy.$t('xyz.form.zcjdr_rule'))],
                bank: [utils.UtilPub.commonValidRule(proxy.$t('xyz.form.bank_rule'))],
                customerId: [utils.UtilPub.commonValidRule(proxy.$t('xyz.form.customerId_rule'))],
            },
            otherParams:{
                disableBfd:true,//是否可以输入信用证不符点
                cardFrom:'',//从哪个模块来
                bankData:[],//银行下拉数据
                customerData:[],//客户下拉数据
                activeName:'baseInfo',//激活的tab
                processInfo:{//审核对象
                    visible:false,//是否显示弹出框
                    status:0,//审核结果（0-通过 1-驳回 2-不通过）
                    desc:'',//审核附言
                },
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new XyzCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'bz'==params.comboId){
                    return [{value:0,label:'人民币'},{value:1,label:'美元'}]
                }
                if(params && 'bank'==params.comboId){
                    return dataObj.otherParams.bankData;
                }
                if(params && 'customerId'==params.comboId){
                    return dataObj.otherParams.customerData;
                }
                if(params && 'F_PAY_TYPE'==params.comboId){
                    return FinanceUtils.buildPayTypeData(params);
                }
            }
        })
        //明细表格初始化参数
        const cardDetailParam=computed(()=>{
            return (detailType:string) => {
                return {
                    detailParam: {
                        toolBar: [{
                            id: "chooseOrder",
                            title: "选择订单",
                            type: "primary",
                            icon: "iconfont icon-chaxun",
                            clickEvent: 'chooseOrder',
                            param:{}
                        }],
                        gridTitle:'信用证订单明细',
                        readOnly:dataObj.disabled,
                        canAdd: false,
                        canPage: false,
                        queryParam: {
                            detailType: detailType
                        },
                        modelMethod: utils.OrderProviderApi.buildUrl('/xyz/detail?t='+Math.random()*1000)
                    }
                }
            }
        })
        const buildUploadInitParams=computed(()=>{
            return (params:any) => {
                return {
                    belongMaxId:dataObj.form.id,
                    fileSize:80,
                    // limitNum:1,
                    accept:'.txt,.jpg,.jpeg,.png,.pdf',
                    disabled:dataObj.disabled,
                    showFileList:true,
                    uploadType:params.uploadType,
                    type:params.type,
                    title:params.title
                }
            }
        })
        //计算应收预付款、尾款、订单总金额
        const payMoney=computed(()=>{
            return (params:any) => {
                return FinanceUtils.calAmount(params);
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=async (newValue:string,selectId:string)=>{
            if('bz'==selectId){
                dataObj.refMap.get('xyzDetail').clearDetailData();
                dataObj.otherParams.customerData=await utils.OrderProviderApi.getAllCustomer({params:{from:'xyz',bz:newValue}});//查询所有客户
            }
            if('bank'==selectId){}
            if('customerId'==selectId){
                dataObj.refMap.get('xyzDetail').clearDetailData();
            }
            if('F_PAY_TYPE'==selectId){
                //付款类型改变了，重新结算整个信用证要用到的金额
                let existRows=dataObj.refMap.get('xyzDetail').getTbInst().tbData;
                dataObj.form.amount=FinanceUtils.gridOrderTotalAmount(existRows);
            }
        }
        //下载附件
        const handlePreview=(file:any)=>{
            utils.Tools.configBox({
                message:'即将下载该附件，是否继续？',
                sureFn: ()=>{
                    let params={id:file.id,fileName:file.name};
                    utils.Api.downLoad(params)
                }
            });
        }
        //选择订单按钮事件
        const chooseOrder=async ()=>{
            if(!dataObj.form.customerId){
                proxy.$message('请先选择客户');
                return;
            }
            await utils.UtilPub.openDialog({customerId:dataObj.form.customerId,bz:dataObj.form.bz,proxy:proxy,modelComp:FormalOrderList,ownerComp:proxy,
                from:'xyz',overflow:'hidden',title: '选择订单',contentHeight:'',showFooterBtn:true,fullscreen:true})
        }
        //选择订单弹出框确定事件
        const sureHandler=async (engineRef:any)=>{
            let selectRows=engineRef.pageListRef.getTbInst().getSelection();
            FinanceUtils.appendSelOrder(selectRows,dataObj,'xyzDetail');
            return true;
        }
        //日期选择改变事件
        const dateChange=(newValue:string,selectId:string)=>{
            if('zczq'==selectId){//最迟交单日=最迟装期+21
                dataObj.form.zcjdr=utils.UtilPub.formatDate(utils.UtilPub.getAfterDayDate(newValue,21));
            }
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            //卡片可以修改的状态
            // let editStatusArr=[utils.Const.status_0,utils.Const.status_2,utils.Const.status_4,utils.Const.status_6,utils.Const.status_8];
            let rollbackStatusArr=[utils.Const.xyz_status_2,utils.Const.xyz_status_4,utils.Const.xyz_status_6,utils.Const.xyz_status_8];
            let editStatusArr=[utils.Const.xyz_status_0];
            //只有从信用证列表打开信用证卡片且信用证没有提交过的时候，信用证卡片才能修改
            if(!editStatusArr.includes(res.data.status) || "XyzList"!=engineInst.engineParams.cardFrom || res.data.invalidFlag==1){
                dataObj.disabled=true;
                dataObj.refMap.get('xyzDetail').setReadOnly(true);
            }
            //如果是退回状态，则可以填入信用证不符点
            if(rollbackStatusArr.includes(res.data.status)){
                dataObj.otherParams.disableBfd=false;
            }
            //不符点排序
            res.data.xyzBfdHistoryBeanList.sort((item1:any,item2:any)=>{
                return item1.time.localeCompare(item2.time);
            });
            //审核意见排序
            res.data.xyzProcessDetailBeanList.sort((item1:any,item2:any)=>{
                return item1.time.localeCompare(item2.time);
            });
            //记录从哪里进入信用证卡片
            dataObj.otherParams.cardForm=engineInst.engineParams.cardFrom;
            //信用证交单卡片里面的交单历史、信用证交单列表、银行入账列表、收款确认列表，可以查看具体的信用证，也会传入cardFrom:xyzCard|SkqrList，这个时候直接选中订单信息tab，体验效果更好
            let cardFormArr=['xyzJdCard','xyzJdList','xyzYhrzList','SkqrList'];
            if(cardFormArr.includes(engineInst.engineParams.cardFrom))dataObj.otherParams.activeName='orderInfo';
        }
        //传入参数到后台
        const setParam=(engineInst:any)=>{
            return {cardFrom:engineInst.engineParams.cardFrom};
        }
        //行开始编辑事件回调方法，点击某一行编辑或新增一行都会调用该方法。区别：新增行的时候不会传入options,options为undifine，编辑的时候options为{row: row, column: column}
        const beganEdit=async(detailType:string,options:any)=>{return true;}
        //结束编辑行的时候，为该行指定列设置显示文本，方便不是编辑状态的情况下显示
        const beforeEndEdit=(options:any)=>{
            if('xyzDetail'==options.detailType){
                options.row.F_PAY_TYPE_NAME = dataObj.refMap.get('F_PAY_TYPE').selected.currentLabel;//设置下拉select选取的文本值到行中
                dataObj.refMap.get('xyzDetail').setRowData(options.row, options.index);
            }
            return true;
        }
        //删除一行明细之后
        const afterDel=(options:any)=>{
            //删除一行之后，重新结算整个信用证要用到的金额
            let existRows=dataObj.refMap.get('xyzDetail').getTbInst().tbData;
            dataObj.form.amount=FinanceUtils.gridOrderTotalAmount(existRows);
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=async ()=>{
            //验证明细表格数据是否合法
            let xyzDetailData=dataObj.refMap.get('xyzDetail').getDetailData();
            let result:boolean=FinanceUtils.verifySaveData(xyzDetailData,proxy);
            return result;
        }
        //提交审批
        const xyzSubmitHandler=async ()=>{
            await dataObj.utilInst.submit();
        }
        //通过
        const passHandler=()=>{
            dataObj.utilInst.openShDialog(0);
        }
        //退回
        const rollBackHandler=()=>{
            dataObj.utilInst.openShDialog(1);
        }
        //审核[通过、退回]确定事件
        const processHandler=async ()=>{
            await dataObj.utilInst.doProcess();
        }
        //查看pi弹出框
        const piDetail=async (piId:string)=>{
            await utils.UtilPub.openDialog({proxy:proxy,addOrEdit:'edit',id:piId,modelComp:PiCard,ownerComp:proxy,cardFrom:'xyzCard',
                title:'pi详情',contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true})
        }
        //查看信用证交单弹出框
        const xyzJdDetail=async (xyzjdId:string)=>{
            await utils.UtilPub.openDialog({proxy:proxy,addOrEdit:'edit',id:xyzjdId,modelComp:XyzJdCard,ownerComp:proxy,cardFrom:'xyzCard',
                title:'交单详情',overflow:'hidden',showFooterBtn:false,fullscreen:false})
        }
        //作废
        const invalidHandle=async ()=>{
            await dataObj.utilInst.doInvalid();
        }
        return{
            ...toRefs(dataObj),proxy,comboSelect,selectOnChange,cardDetailParam,beganEdit,beforeEndEdit,afterDel,beforeOpen,setParam,beforeSaveHandler,
            buildUploadInitParams,handlePreview,
            chooseOrder,sureHandler,payMoney,dateChange,xyzSubmitHandler,passHandler,rollBackHandler,processHandler,piDetail,xyzJdDetail,invalidHandle
        }
    }
});