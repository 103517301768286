import BaseBean from "@/utils/BaseBean";

export interface IXyzYhrzListDataObj {
    utilInst:XyzYhrzListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class XyzYhrzListUtil extends BaseBean{
    public dataObj:IXyzYhrzListDataObj

    constructor(proxy:any,dataObj:IXyzYhrzListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}