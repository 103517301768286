import BaseBean from "@/utils/BaseBean";

export interface IXyzCardDataObj {
    utilInst:XyzCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class XyzCardUtil extends BaseBean{
    public dataObj:IXyzCardDataObj;

    constructor(proxy:any,dataObj:IXyzCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        this.dataObj.otherParams.bankData=await this.utils.OrderProviderApi.getAllBank({});//查询所有银行
        this.dataObj.otherParams.customerData=await this.utils.OrderProviderApi.getAllCustomer({params:{from:'xyz',bz:this.dataObj.form.bz}});//查询所有客户
    }

    //提交审批
    public async submit():Promise<void>{
        this.utils.Tools.configBox({
            message:'确定提交吗?',
            sureFn:async ()=> {
                this.dataObj.form.saveType='submit';
                await this.proxy.engine.saveHandler();
                // let res=await this.utils.OrderProviderApi.xyzSubmit({params:{id:this.dataObj.form.id,bfd:this.dataObj.form.bfd}});
                // if(res.result){
                //     this.utils.Tools.success({message:'操作成功'});
                //     await this.proxy.engine.engineUtil.doAddOrLoad(this.dataObj.form.id,'card');
                //     this.proxy.engine.engineParams.ownerComp.queryHandler(true)
                // }
            }
        });
    }
    //审核[通过、退回]确定事件
    public async doProcess():Promise<any>{
        let res=await this.utils.OrderProviderApi.processXyz({
            params:{
                status:this.dataObj.otherParams.processInfo.status,
                desc:this.dataObj.otherParams.processInfo.desc,
                id:this.dataObj.form.id
            }
        });
        if(res.result){
            this.utils.Tools.success();
            this.dataObj.otherParams.processInfo.visible=false;
            this.proxy.engine.engineParams.ownerComp.queryHandler(true)
            this.proxy.dialogInst.dialogVisible=false;
        }
    }
    public openShDialog(status:number):void{
        this.dataObj.otherParams.processInfo.status=status;
        this.dataObj.otherParams.processInfo.visible=true;
    }
    //作废
    public async doInvalid():Promise<void>{
        this.utils.Tools.configBox({
            message:'确定作废吗?',
            sureFn:async ()=> {
                let res=await this.utils.OrderProviderApi.xyzInvalid({params:{id:this.dataObj.form.id}});
                if(res.result){
                    this.utils.Tools.success({message:'操作成功'});
                    await this.proxy.engine.engineUtil.doAddOrLoad(this.dataObj.form.id,'card');
                    this.proxy.engine.engineParams.ownerComp.queryHandler(true)
                }
            }
        });
    }
}