export default {
    zh:{
        pi:{
            queryParam:{
                ycg:'原产国',
                ycg_placeholder:'请输入原产国',
                jhrq:'交货日期',
                jhrq_placeholder:'请输入交货日期',
                sfzy:'是否转运',
                sfzy_placeholder:'请输入是否转运',
            },
            columns:{
                F_CODE:'PI编码',
                F_FLAG:'是否发布',
                F_NAME:'PI名称',
            },
            form:{
                templateId:'PI模板',
                templateId_placeholder:'请输入PI模板',
                piId:'套用PI',
                piId_placeholder:'请输入套用PI',
                code:'PI编码',
                code_placeholder:'请输入PI编码',
                name:'PI名称',
                name_placeholder:'请输入PI名称',
                name_rule: '请输入PI名称',
                qfrq:'签发日期',
                qfrq_placeholder:'请选择签发日期',
                qfrq_rule: '请选择签发日期',
                jhrq:'交货日期',
                jhrq_placeholder:'请选择交货日期',
                jhrq_rule: '请选择交货日期',
                customerDeliveryId:'收货人名称',
                customerDeliveryId_placeholder:'请选择收货人名称',
                jgtk:'价格条款',
                jgtk_placeholder:'请输入价格条款',
                fktk:'付款条款',
                fktk_placeholder:'请输入付款条款',
                zbxy:'质保协议',
                zbxy_placeholder:'请输入质保协议',
                ysfs:'运输方式',
                ysfs_placeholder:'请输入运输方式',
                zyfs:'装运方式',
                zyfs_placeholder:'请输入装运方式',
                jhqx:'交货期限',
                jhqx_placeholder:'请输入交货期限',
                ycg:'原产国',
                ycg_placeholder:'请输入原产国',
                jhdd:'接货地点',
                jhdd_placeholder:'请输入接货地点',
                zyg:'装运港',
                zyg_placeholder:'请输入装运港',
                xhg:'卸货港',
                xhg_placeholder:'请输入卸货港',
                zzmdd:'最终目的地',
                zzmdd_placeholder:'请输入最终目的地',
                fpzy:'分批装运',
                fpzy_placeholder:'请选择分批装运',
                sfzy:'是否转运',
                sfzy_placeholder:'请选择是否转运',
                zlbz:'质量标准',
                zlbz_placeholder:'请输入质量标准',
                hydj:'货运单据',
                hydj_placeholder:'请输入货运单据',
                jgtbsm:'价格特别说明',
                jgtbsm_placeholder:'请输入价格特别说明',
                syrmc:'受益人名称',
                syrmc_placeholder:'请输入受益人名称',
                syrdz:'受益人地址',
                syrdz_placeholder:'请输入受益人地址',
                syrxldh:'受益人联系电话',
                syrxldh_placeholder:'请输入受益人联系电话',
                syrcz:'受益人传真',
                syrcz_placeholder:'请输入受益人传真',
                zhhm:'账户号码',
                zhhm_placeholder:'请输入账户号码',
                syh:'受益行',
                syh_placeholder:'请输入受益行',
                syhdz:'受益行地址',
                syhdz_placeholder:'请输入受益行地址',
                syhSwift:'受益行SWIFT BIC',
                syhSwift_placeholder:'请输入受益行SWIFT BIC',
                zjh:'中间行',
                zjh_placeholder:'请输入中间行',
                zjhSwift:'中间行SWIFT BIC',
                zjhSwift_placeholder:'请输入中间行SWIFT BIC'
            },
        }
    },
    en:{
        pi:{
            queryParam:{
                ycg:'原产国',
                ycg_placeholder:'请输入原产国',
                jhrq:'交货日期',
                jhrq_placeholder:'请输入交货日期',
                sfzy:'是否转运',
                sfzy_placeholder:'请输入是否转运',
            },
            columns:{
                F_CODE:'PI编码',
                F_FLAG:'是否发布',
                F_NAME:'PI名称',
            },
            form:{
                templateId:'PI模板',
                templateId_placeholder:'请输入PI模板',
                piId:'套用PI',
                piId_placeholder:'请输入套用PI',
                code:'PI编码',
                code_placeholder:'请输入PI编码',
                name:'PI名称',
                name_placeholder:'请输入PI名称',
                name_rule: '请输入PI名称',
                qfrq:'签发日期',
                qfrq_placeholder:'请选择签发日期',
                qfrq_rule: '请选择签发日期',
                jhrq:'交货日期',
                jhrq_placeholder:'请选择交货日期',
                jhrq_rule: '请选择交货日期',
                customerDeliveryId:'客户收货人名称',
                customerDeliveryId_placeholder:'请选择客户收货人名称',
                jgtk:'价格条款',
                jgtk_placeholder:'请输入价格条款',
                fktk:'付款条款',
                fktk_placeholder:'请输入付款条款',
                zbxy:'质保协议',
                zbxy_placeholder:'请输入质保协议',
                ysfs:'运输方式',
                ysfs_placeholder:'请输入运输方式',
                zyfs:'装运方式',
                zyfs_placeholder:'请输入装运方式',
                jhqx:'交货期限',
                jhqx_placeholder:'请输入交货期限',
                ycg:'原产国',
                ycg_placeholder:'请输入原产国',
                jhdd:'接货地点',
                jhdd_placeholder:'请输入接货地点',
                zyg:'装运港',
                zyg_placeholder:'请输入装运港',
                xhg:'卸货港',
                xhg_placeholder:'请输入卸货港',
                zzmdd:'最终目的地',
                zzmdd_placeholder:'请输入最终目的地',
                fpzy:'分批装运',
                fpzy_placeholder:'请选择分批装运',
                sfzy:'是否转运',
                sfzy_placeholder:'请选择是否转运',
                zlbz:'质量标准',
                zlbz_placeholder:'请输入质量标准',
                hydj:'货运单据',
                hydj_placeholder:'请输入货运单据',
                jgtbsm:'价格特别说明',
                jgtbsm_placeholder:'请输入价格特别说明',
                syrmc:'受益人名称',
                syrmc_placeholder:'请输入受益人名称',
                syrdz:'受益人地址',
                syrdz_placeholder:'请输入受益人地址',
                syrxldh:'受益人联系电话',
                syrxldh_placeholder:'请输入受益人联系电话',
                syrcz:'受益人传真',
                syrcz_placeholder:'请输入受益人传真',
                zhhm:'账户号码',
                zhhm_placeholder:'请输入账户号码',
                syh:'受益行',
                syh_placeholder:'请输入受益行',
                syhdz:'受益行地址',
                syhdz_placeholder:'请输入受益行地址',
                syhSwift:'受益行SWIFT BIC',
                syhSwift_placeholder:'请输入受益行SWIFT BIC',
                zjh:'中间行',
                zjh_placeholder:'请输入中间行',
                zjhSwift:'中间行SWIFT BIC',
                zjhSwift_placeholder:'请输入中间行SWIFT BIC'
            },
        }
    }
}