import {reactive, toRefs, computed,defineComponent,onBeforeMount,onMounted,getCurrentInstance,nextTick} from 'vue';
import PiCardUtil,{IPiCardDataObj} from './piCardUtil';
import Order from '@/views/project/order/utils/order/Order.vue';
import language from './piLanguage'
import T001Card from "@/views/project/order/order/piTemplate/t001/T001Card.vue";
import T003Card from "@/views/project/order/order/piTemplate/t003/T003Card.vue";
export default defineComponent({
    name:'PiCard',
    title:'PI',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:IPiCardDataObj=reactive<IPiCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                showBackTarget:false,//是否显示返回顶部小图标，tmd，居然说挡住他们了
                hasDetails: false,
                modelPath: utils.OrderProviderApi.buildUrl('/pi')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                templateId: [utils.UtilPub.commonValidRule('请选择PI模板')],
                name: [utils.UtilPub.commonValidRule(proxy.$t('pi.form.name_rule'))],
                qfrq: [utils.UtilPub.commonValidRule(proxy.$t('pi.form.qfrq_rule'))],
                jhrq: [utils.UtilPub.commonValidRule(proxy.$t('pi.form.jhrq_rule'))]
            },
            importGuidParams:{
                ownerInst:proxy,
                uploadParams:{
                    saveType:0,
                    action:utils.Api.buildUrl('/piTemplate/importData')
                },
                downloadParams:{
                    sourceName:'russiaImportTemplate.xlsx',
                    downLoadFileName:'俄罗斯PI模板.xlsx',
                    action:utils.Api.buildUrl('/pi/downLoadResourceFile')
                }
            },
            otherParams:{
                rollBackDisable:true,//驳回的情况，有些字段不受disabled控制，而受这个字段控制
                whDisable:true,//维护的情况，有些字段不受disabled控制，而受这个字段控制
                isNewPi:false,//是新增PI还是编辑PI
                showHis:false,//是否展示修改历史按钮
                activeName:'baseInfo',//当前激活tab
                piTemplateData:[],//pi模板
                piData:[],//pi历史下拉数据，帮助填充当前表单
                order:{//控制订单哪些属性显示/影藏的对象
                    priceFlag:true,disablePrice:true,sumAmountFlag:true,numEditFlag:false,pccFlag:false,
                    otherInfoFlag:true,disableOtherInfo:true,addBtnFlag:false,disableCustomer:true,customerDelivery:true,
                    disablePcc:true,showHis:false
                },
                shTbData:[],//PI审核历史信息
                accessoryData:[],//配件信息
                processInfo:{//审核对象
                    visible:false,//是否显示弹出框
                    status:0,//审核结果（0-通过 1-驳回 2-不通过）
                    desc:'',//审核附言
                },
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new PiCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'templateId'==params.comboId){
                    return dataObj.otherParams.piTemplateData;
                }
                if(params && 'piId'==params.comboId){
                    return dataObj.otherParams.piData;
                }
                if(params && 'fpzy'==params.comboId){
                    return [{value:0,label:'允许'},{value:1,label:'不允许'}]
                }
                if(params && 'sfzy'==params.comboId){
                    return [{value:0,label:'允许'},{value:1,label:'不允许'}]
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=async (newValue:any,selectId:string)=>{
            if('templateId'==selectId){
                dataObj.form.piId='';
                dataObj.otherParams.piData=[];
                if(newValue)await dataObj.utilInst.buildPiData({params:{templateId:newValue}});
            }
            if('piId'==selectId){
                if(newValue && newValue.value!=dataObj.form.id){//选择的是自己，则不管了，否则用其值填充当前表单
                    let item=Object.assign({},newValue.item);
                    //以下是不需要填充的属性，把它冲item中删除，然后再把item赋值给form
                    let delProps = ['id','orderId','code', 'piId','qfrq','name','jhrq','jhrqHis','flag','pubTime','jgtkHis','fktkHis','addTime',
                        'ywUserId','ywTime','cwUserId','cwTime','cwzjUserId','cwzjTime','tjUserId','tjTime','jsonContent','status','processDetailBeanList',
                        'lockFlag','orderCode','pubUserId','sureFlag','sureTime','ysfsHis','templateId','sysAddUser','sysLastTime','sysLastUser','sysVersion'];
                    for (let prop of delProps)delete item[prop];
                    Object.assign(dataObj.form,item);
                }
            }
            if('fpzy'==selectId){}
            if('sfzy'==selectId){}
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            //不能根据addOrLoad来判断，因为从OrderList那里拟定pi可以进入picard，这个时候永远都是add，但是有可能已经拟定了pi
            if(res.data.id)dataObj.otherParams.isNewPi=true;
            if(engineInst.engineParams.cardFrom=='PiList'||engineInst.engineParams.cardFrom=='OrderList' || engineInst.engineParams.cardFrom=='DraftPiList'){//从PI管理主界面进来
                if(res.data.flag==1){//pi已经发布，那么部分字段就可以维护了，且历史按钮就可以展示了
                    dataObj.disabled=true;
                    dataObj.otherParams.whDisable=false;
                    dataObj.otherParams.showHis=true;//发布之后，可以维护了，那么把维护的历史按钮显示出来
                    dataObj.otherParams.order.disablePrice=false;//发布之后，可以维护，那么单价就可以修改了
                    dataObj.otherParams.order.showHis=true;//展示订单单价和总美元的变化历史
                }else{//还未发布
                    let rollBack=[utils.Const.status_3,utils.Const.status_5,utils.Const.status_9,utils.Const.status_13];//好想干掉3这种情况
                    let notSubmit=utils.Const.status_2;
                    //还未提交或者驳回
                    if(rollBack.includes(res.data.status) || notSubmit==res.data.status){
                        dataObj.otherParams.rollBackDisable=false;
                        dataObj.otherParams.order.disablePrice=false;
                        dataObj.otherParams.order.disableOtherInfo=false;
                        if(rollBack.includes(res.data.status))dataObj.disabled=true;//驳回
                    }else{
                        dataObj.disabled=true;
                    }
                }
            }else{//不是从pi管理主界面进来
                dataObj.disabled=true;
            }

            nextTick(()=>{//只能放到nextTick里面，以防order还未初始化好
                dataObj.refMap.get('order').otherParams.orderData=JSON.parse(res.data.jsonContent);
                if(dataObj.refMap.get('order').otherParams.orderData.customer && dataObj.refMap.get('order').otherParams.orderData.customer.customerId){
                    dataObj.refMap.get('order').utilInst.getCustomerAccountByCustomerId(dataObj.refMap.get('order').otherParams.orderData.customer.customerId.value);
                }
            })
            //处理审核历史数据
            dataObj.utilInst.formatShData(res);
            if(res.data.templateId)await dataObj.utilInst.buildPiData({params:{templateId:res.data.templateId}});
            if(res.data && res.data.piId){
                dataObj.form.piId=dataObj.otherParams.piData.find((item:any)=>item.value==res.data.piId)
            }
            if(res.accessoryData){
                dataObj.otherParams.accessoryData=[];
                Object.keys(res.accessoryData.accessoryData).forEach((key:string)=>{
                    let item=JSON.parse(key);
                    item.child=res.accessoryData.accessoryData[key];
                    if(item.child && item.child.length>0){
                        item.modelName=item.child[0]['model'];
                    }
                    dataObj.otherParams.accessoryData.push(item);
                });
            }
            //在信用证卡片查看pi得时候，会传入cardFrom：xyzCard|oaApplyCard|SkqrList|XbfsList，这个时候直接打开订单页签吧，估计就是为了查看价格
            let cardFromArr=['xyzCard','oaApplyCard','SkqrList','XbfsList'];
            if(cardFromArr.includes(engineInst.engineParams.cardFrom)){dataObj.otherParams.activeName='orderInfo'}
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return dataObj.utilInst.checkSaveData();
        }
        //表单保存验证失败之后结果
        const afterErrorValid=(engineInst:any)=>{
            dataObj.otherParams.activeName='baseInfo';
        }
        //加载pi参数
        const setParam=(engineInst:any)=>{
            return {orderId:engineInst.engineParams.orderId,cardFrom:engineInst.engineParams.cardFrom};
        }
        //pi提交[pi草稿提交审批、业务驳回后PI提交，财务驳回后PI提交]
        const piSubmitHandler=async ()=>{
            dataObj.form.saveType='piSubmit';
            await proxy.engine.saveHandler();
        }
        //发布PI
        const pubPiHandler=async ()=>{
            dataObj.form.saveType='pubPi';
            await proxy.engine.saveHandler();
        }
        //保存之后
        const afterSaveHandler=(res:any)=>{
            dataObj.utilInst.jumpNote(res);
            dataObj.form.saveType='';
        }
        //通过按钮点击
        const passHandler=()=>{
            dataObj.otherParams.processInfo={visible:true,status: 0,desc:''};
        }
        //驳回按钮点击
        const rollBackHandler=()=>{
            dataObj.otherParams.processInfo={visible:true,status: 1,desc:''};
        }
        //不通过按钮点击
        const notPassHandler=()=>{
            dataObj.otherParams.processInfo={visible:true,status: 2,desc:''};
        }
        //审核[业务、财务--通过、不通过、驳回]
        const processHandler=async ()=>{
           await dataObj.utilInst.doProcess();
        }
        //意向订单转正式订单
        const zzsddHandler=()=>{
            dataObj.utilInst.doZz_invalid(0);
        }
        //意向订单作废
        const invalidHandle=()=>{
            dataObj.utilInst.doZz_invalid(1);
        }
        //导入按钮事件
        const importHandler=()=>{
            dataObj.importGuidParams.uploadParams.piId=dataObj.form.id;
            dataObj.refMap.get('importGuid').importDataDialogVisible=true
        }
        //导入完成之后
        const afterResult=async ()=>{
            await proxy.engine.engineUtil.doAddOrLoad(dataObj.form.id,'card');
            dataObj.refMap.get('importGuid').importDataDialogVisible=false;
        }
        //pi预览
        const piPreViewHandler=async ()=>{
            let piId=dataObj.form.id;
            let item=dataObj.otherParams.piTemplateData.find((item:any)=>{
                return item.value==dataObj.form.templateId;
            })
            if(item){
                let modelComp;
                switch (item.item.code) {
                    case '001':modelComp=T001Card;break;
                    case '002':break;
                    case '003':modelComp=T003Card;break;
                }
                await utils.UtilPub.openDialog({proxy:proxy,addOrEdit:'edit',id:item.value,modelComp:modelComp,ownerComp:proxy,from:'PiCard',
                    piId:piId,
                    title: item.label,contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true,code:item.item.code,templateId:item.value})
            }
        }
        return{
            ...toRefs(dataObj),proxy,utils,comboSelect,selectOnChange,beforeOpen,beforeSaveHandler,afterErrorValid,setParam,
            piSubmitHandler,pubPiHandler,afterSaveHandler,passHandler,notPassHandler,rollBackHandler,processHandler,
            zzsddHandler,invalidHandle,importHandler,afterResult,piPreViewHandler
        }
    },
    components: {
        Order
    }
});