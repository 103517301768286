import {reactive, toRefs, defineComponent, onBeforeMount, onMounted, getCurrentInstance, nextTick, computed} from 'vue';
import FormalOrderDetailCardUtil ,{IFormalOrderDetailCardDataObj} from './formalOrderDetailCardUtil';
import Produce from "@/views/project/order/order/formalOrder/produce/Produce.vue";
import SplitOrder from "@/views/project/order/order/formalOrder/splitOrder/SplitOrder.vue";
import DzzlCard from "@/views/project/order/fy/zl/dzzl/DzzlCard.vue";
export default defineComponent({
    name:'FormalOrderDetail',
    title:'订单详情',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IFormalOrderDetailCardDataObj=reactive<IFormalOrderDetailCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: false,
                modelPath: utils.OrderProviderApi.buildUrl('/formalOrder')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {},
            otherParams:{
                dzzl:{
                    showDzzl:false,//是否展示单证资料tab，只有从单证资料列表打开才展示
                    childOrderid:'',//单证资料列表打开会展示单证资料页签，在这里还可以上传附件，附件归属到子订单id上面
                    bgzlBean:{}//报关资料保存在数据库中的bean信息
                },
                childOrderCode:'',//从HdxxList过来的时候会传入childOrderCode，这个时候，只显示这个子订单
                activeName:'first',
                orderId:'',
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new FormalOrderDetailCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        const buildUploadInitParams=computed(()=>{
            return (params:any) => {
                return {showFileList:true, uploadType:params.uploadType, type:params.type, title:params.title};
            }
        })
        //下载附件
        const handlePreview=(file:any)=>{
            utils.Tools.configBox({
                message:'即将下载该附件，是否继续？',
                sureFn: ()=>{
                    let params={id:file.id,fileName:file.name};
                    utils.Api.downLoad(params)
                }
            });
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            dataObj.otherParams.childOrderCode=engineInst.engineParams.childOrderCode;
            await dataObj.utilInst.initPage();
            if('DzzlList'==engineInst.engineParams.cardFrom){
                dataObj.otherParams.dzzl.showDzzl=true;
                dataObj.otherParams.dzzl.childOrderid=engineInst.engineParams.childOrderid;
                dataObj.otherParams.dzzl.bgzlBean=res.bgzlBean;
            }
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }
        const setParam=(engineInst:any)=>{
            //只有从单证资料列表cardFrom=DzzlList打开的时候，才有childOrderid，type，把他们传到后台去吧，方便构建单证资料附件信息
            return {
                orderId:engineInst.engineParams.orderId,
                cardFrom:engineInst.engineParams.cardFrom,
                childOrderid:engineInst.engineParams.childOrderid,
                type:engineInst.engineParams.type,
            };
        }

        return{
            ...toRefs(dataObj),proxy,buildUploadInitParams,handlePreview,beforeOpen,beforeSaveHandler,setParam
        }
    },
    components:{
        Produce,
        SplitOrder,
        DzzlCard
    }
});