import PiCard from '../pi/PiCard.vue';
import {reactive, toRefs, defineComponent, onBeforeMount, onMounted, getCurrentInstance, onActivated, computed, provide,nextTick} from 'vue';
import FormalOrderListUtil ,{IFormalOrderListDataObj} from './formalOrderListUtil';
import Produce from "@/views/project/order/order/formalOrder/produce/Produce.vue";
import FormalOrderDetail from "@/views/project/order/order/formalOrder/formalOrderDetail/FormalOrderDetail.vue";
import OrderFlowStep from "@/views/project/order/order/formalOrder/orderFlowStep/OrderFlowStep.vue";
import FinanceUtils from "@/views/project/order/finance/financeUtils";

export default defineComponent ({
    name: 'FormalOrderListUtil',
    props: {
        //以弹出框的形式包含该模块的时候会传入该参数(由jh.core.js的dialog传入)，在此模块中用该参数控制固定列的显示
        engineParams: {
            type: Object
        }
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('uploadOwner', proxy);
        let dataObj:IFormalOrderListDataObj=reactive<IFormalOrderListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                gridTitle:'正式订单管理',
                isShowFixCol:false,
                multiple:props.engineParams?true:false,//由弹出包含订单列表的时候，显示复选框
                queryParam: {
                    from:props.engineParams?props.engineParams.from:'',//传给后台，决定是否显示顶部按钮，弹窗包含列表不显示，通过左边菜单打开列表则显示对应按钮
                    customerId:props.engineParams?props.engineParams.customerId:'',//传给后台，决定是否按客户过滤
                    bz:props.engineParams?props.engineParams.bz:'',//传给后台，决定是否按币种过滤
                    type:props.engineParams?props.engineParams.type:''//传给后台，按用途种过滤（tt支付专用）
                },
                cardFrom:'FormalOrderList',
                modelComp: PiCard,
                modelMethod: utils.OrderProviderApi.buildUrl('/formalOrder/pageData')
            },
            otherParams:{
                count:0,
                activeName:'all',
                belongMaxId:'',
                visible:false,//订单评审弹出框隐藏/显示控制标识
                fpdzzy:{//分配单证专员
                    visible:false,//分配单证专员弹出框隐藏/显示控制标识
                    selectOrders:[],//选择的子订单
                    childOrders:[],//子订单下拉数据集
                    dzzy:'',//选择的单证专员
                    dzzyData:[],//所有的单证专员下拉数据集
                }
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new FormalOrderListUtil(proxy,dataObj);
        })
        onMounted(()=>{

        })
        onActivated(()=>{

        })
        //---------------------------computed---------------------------
        const buildUploadInitParams=computed(()=>{
            return (params:any) => {
                return {
                    belongMaxId:dataObj.otherParams.belongMaxId,
                    fileSize:80,
                    accept:'.xlsx,.xls,.csv,.pdf',
                    showFileList:true,
                    uploadType:params.uploadType,
                    type:params.type,
                    title:params.title
                }
            }
        })
        //下载附件
        const handlePreview=(file:any)=>{
            utils.Tools.configBox({
                message:'即将下载该附件，是否继续？',
                sureFn: ()=>{
                    let params={id:file.id,fileName:file.name};
                    utils.Api.downLoad(params)
                }
            });
        }
        const formatPageInfo=(options:any)=>{
            return options;
        }

        //维护订单评审表按钮事件
        const ddpsbHandler=async ()=>{
            let selectRows = dataObj.utilInst.getSelectedRow();
            if(selectRows){
                dataObj.otherParams.visible=true;
                //只是这样修改了，在upload组件中不会改变belongMaxId的值，必须在nextTick里面显示的改变upload中的belongMaxId，才会发起加载附件的请求
                //否则每行记录打开的订单评审表都一样
                // dataObj.otherParams.belongMaxId=selectRows[0].F_ORDER_ID;
                nextTick(()=>{
                    dataObj.refMap.get('ddpsb').uploadParams.belongMaxId=selectRows[0].F_ORDER_ID;
                })
            }
        }
        //拆单按钮事件
        const splitOrderHandler=async ()=>{
            let selectRows = dataObj.utilInst.getSelectedRow();
            if(selectRows){
                if(selectRows[0].F_SPLIT_FLAG==0)
                    await dataObj.utilInst.doSplitHandler(selectRows);
                else proxy.$message('不允许执行该操作');
                // await dataObj.utilInst.doSplitHandler(selectRows);
            }
        }
        //维护生产信息按钮事件
        const produceHandler=async ()=>{
            let selectRows = dataObj.utilInst.getSelectedRow();
            if(selectRows){
                if(selectRows[0].F_SPLIT_FLAG==1 || selectRows[0].F_SPLIT_FLAG==2){
                    await utils.UtilPub.openDialog({id:'',proxy:proxy,addOrEdit:'add',modelComp:Produce,ownerComp:dataObj.pageListRef,
                        orderId:selectRows[0].F_ORDER_ID,
                        cardFrom:'FormalOrderProduce',overflow:'hidden',title: '生产信息',contentHeight:'',showFooterBtn:false,fullscreen:true})
                }else proxy.$message('不允许执行该操作');
            }
        }
        //分配单证专员
        const fpdzzyHandler=async ()=>{
            let selectRows = dataObj.utilInst.getSelectedRow();
            if(selectRows){
                if(selectRows[0].F_SPLIT_FLAG==1 || selectRows[0].F_SPLIT_FLAG==2){
                    await dataObj.utilInst.initDzzyDialog(selectRows);
                }else proxy.$message('不允许执行该操作');
            }
        }
        //分配单证专员确认事件
        const fpdzzySureHandler=async()=>{
            await dataObj.utilInst.doFpdzzy();
        }

        //表格加载完毕
        const gridLoaded=(res:any)=>{
            dataObj.otherParams.count=res.count;
            dataObj.otherParams.fpdzzy.dzzyData=[];
            res.allDzzy.forEach((item:any)=>{
                dataObj.otherParams.fpdzzy.dzzyData.push({value:item.id,label:item.name,disabled:item.status==1});
            })
        }
        //查看订单分配和维护等详细信息
        const orderDetail=async (row:any)=>{
            await utils.UtilPub.openDialog({id:'',proxy:proxy,addOrEdit:'add',modelComp:FormalOrderDetail,ownerComp:dataObj.pageListRef,
                orderId:row.F_ORDER_ID,
                cardFrom:'FormalOrderDetail',overflow:'hidden',title: '正式订单详情',contentHeight:'',showFooterBtn:false,fullscreen:true})
        }
        //查看pi弹出框
        const piDetail=async (piId:string)=>{
            await utils.UtilPub.openDialog({proxy:proxy,addOrEdit:'edit',id:piId,modelComp:PiCard,ownerComp:proxy,cardFrom:'FormalOrderList',
                title:'pi详情',contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true})
        }
        //查看当前进度
        const flowStepHandler=async (row:any)=>{
            await utils.UtilPub.openDialog({id:'',proxy:proxy,addOrEdit:'add',modelComp:OrderFlowStep,ownerComp:dataObj.pageListRef,
                orderId:row.F_ORDER_ID,
                cardFrom:'OrderFlowStep',overflow:'hidden',title: '订单进度详情',contentHeight:'',showFooterBtn:false,fullscreen:true})
        }
        //计算尾款
        /**
         * 本来后台已经传来了尾款_f_wk,为什么不直接用，要计算一遍呢？
         * 因为在前台对预付款和尾款都用utils.UtilPub.toAmountFormat的话，那么就相当于预付款和尾款都进行了四舍五入，
         * 这个时候，就可能出现预付款+尾款！=订单金额的情况。比如后台数据返回的金额是如下情况的时候，就有0.0的误差
         * f_amount=1306.50  _f_yfk=901.485000 _f_wk=405.015000
         * 假如对预付款和尾款都进行四舍五入，那么他们相加就不等于f_amount了，就有0.1的误差了，
         * 于是才用下方的总金额-格式化之后的预付款，来取尾款，这个时候，尾款肯定是两位小数，不可能有四舍五入的情况了
         * */
        const calWk=computed(()=>{
            return (params:any) => {
                let yfk=utils.UtilPub.toAmountFormat(params.row._F_YFK,false,2);
                yfk=utils.UtilPub.to_double(yfk)
                let amount=utils.UtilPub.toAmountFormat(params.row.F_AMOUNT,false,2);
                amount=utils.UtilPub.to_double(amount)
                return utils.UtilPub.toAmountFormat(amount-yfk,false,2);
            }
        })
        return{
            ...toRefs(dataObj),buildUploadInitParams,handlePreview,formatPageInfo,ddpsbHandler,splitOrderHandler,produceHandler,
            fpdzzyHandler,fpdzzySureHandler,gridLoaded,orderDetail,piDetail,flowStepHandler,calWk
        }
    }
});