import BaseBean from "@/utils/BaseBean";

export interface IXyzYhrzCardDataObj {
    utilInst:XyzYhrzCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class XyzYhrzCardUtil extends BaseBean{
    public dataObj:IXyzYhrzCardDataObj;

    constructor(proxy:any,dataObj:IXyzYhrzCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        this.dataObj.otherParams.xyzData=await this.utils.OrderProviderApi.xyzYhrzData({});
    }
    //提交
    public async doSubmit():Promise<any>{
        this.utils.Tools.configBox({
            message:'确定提交吗?',
            sureFn:async ()=> {
                let res=await this.utils.OrderProviderApi.xyzYhrzSubmit({params:{id:this.dataObj.form.id}});
                if(res.result){
                    this.utils.Tools.success({message:'操作成功'});
                    await this.proxy.engine.engineUtil.doAddOrLoad(this.dataObj.form.id,'card');
                }
            }
        });
    }
}