import BaseBean from "@/utils/BaseBean";

export interface IProduceCardDataObj {
    utilInst:ProduceCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class ProduceCardUtil extends BaseBean{
    public dataObj:IProduceCardDataObj;

    constructor(proxy:any,dataObj:IProduceCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData(proxy:any):Promise<void>{

    }
    public async initPage(orderId:string):Promise<any>{
        let res=await this.utils.OrderProviderApi.produceInfo({params:{id:orderId}});
        if(res.result){
            if(res.groupOrderBean){//已经有分组信息了
                this.dataObj.otherParams.groupInfo=JSON.parse(res.groupOrderBean.content);
                this.dataObj.otherParams.groupInfo.forEach((item:any)=>{
                    if(item.pcFlag==0)item.disablePcFlag=true;//如果已排产，那么就不允许修改了
                    if(item.rkFlag==0)item.disableRkFlag=true;//如果已入库，那么就不允许修改了
                });
            }

            this.dataObj.otherParams.orderSplitFlag=res.orderBean.splitFlag;//拆单状态0-未操作(未拆单) 1-不拆单 2-已拆单
            if(res.orderBean.splitFlag==2){//不进行订单拆分或者未操作的时候就点击维护生产信息，这个时候splitOrderBean未null
                //构建已经在分组中使用过子订单号，经过两个map之后成为了一个二维数组，通过flat把二维数组变成了一维
                let usedChildOrders=this.dataObj.otherParams.groupInfo.map((item:any)=>item.tbData.map((item2:any)=>item2.orderCode)).flat();
                this.dataObj.otherParams.childOrders=[];

                //子订单下拉数据集中只展示没有出现在任何分组的子订单
                let content=JSON.parse(res.splitOrderBean.content);
                content.splitInfo.splitDetail.data.forEach((item:any)=>{
                    let childOrderItem={value:item.title,label:item.title,item:item};
                    //备份一下子订单项，在删除生产计划（子订单）后，好从map里面拿到完整的信息归还到子订单下拉数据集中
                    this.dataObj.otherParams.map.set(item.title,childOrderItem);
                    if(!usedChildOrders.includes(item.title))this.dataObj.otherParams.childOrders.push(childOrderItem)
                });
            }else{//未操作(未拆单) 或者不拆单的情况，其实子订单下拉数据集只有一条记录，那就是订单本身
                let item={value:res.orderBean.code,label:res.orderBean.code.substr(2),item:res.orderBean};
                this.dataObj.otherParams.map.set(item.value,item);
                if(!res.groupOrderBean)this.dataObj.otherParams.childOrders.push(item);
            }
        }
    }
    //加入新的分组
    public joinNewGroup():void{
        //检查新创建的分组是否已经存在了
        let isExist=this.dataObj.otherParams.groupInfo.findIndex((item:any)=>item.title===this.dataObj.otherParams.groupDialog.value2);
        if(isExist!=-1){
            this.proxy.$message('该分组名称已经存在了');
            return;
        }
        //新增加一个tab
        this.dataObj.otherParams.groupInfo.push({
            title:this.dataObj.otherParams.groupDialog.value2,//tab的标题-即分组的名称
            tbData:this.buildTbData(),//生产计划号表格数据
            sumNum:this.calSelSumNum(),//本分组拥有子订单的总数量
            oaFlag:0,//是否oa
            oaTime:'',//下单时间
            yjrkrq:'',//预计入库日期
            scjd:0,//生产基地
            pcFlag:1,//是否排产
            sjpcrq:'',//实际排产日期
            rkFlag:1,//是否入库
            sjrkrq:'',//实际入库日期
            sjrksl:0,//实际入库数量
            kfyrq:'',//可发运日期
        });
        this.dataObj.otherParams.activeTab=this.dataObj.otherParams.groupInfo.length-1;//让新添加的tab处于激活状态
        this.afterGroup();
    }
    //往已经存在的组中加入本次选择的子订单
    public joinExistGroup():any{
        this.dataObj.otherParams.groupInfo.map((item:any,index:number)=>{
            if(item.title===this.dataObj.otherParams.groupDialog.value1){//找到已有的分组
                item.tbData=item.tbData.concat(this.buildTbData());//往已有分组中的生产计划表添加选择的子订单
                item.sumNum+=this.calSelSumNum();//更新该分组的子订单总数量
                this.dataObj.otherParams.activeTab=index;//激活选定要加入组所在tab
            }
        });
        this.afterGroup();
    }
    //根据选择的子订单构建生产计划号表格数据
    public buildTbData():any{
        if(this.dataObj.otherParams.orderSplitFlag==1){//里面其实只有一个订单对象
            let item1=this.dataObj.otherParams.selectOrders[0];
            console.log(JSON.parse(item1.item.jsonContent))
            return[{orderCode:item1.label,scjhh:item1.label,sumNum:item1.item.sumNum,childDetail:item1.item.tbData}];
        }else{//里面是所有的子订单对象
            return this.dataObj.otherParams.selectOrders.map((item1:any)=>{
                let sumNum:number=0;
                item1.item.tbData.forEach((item2:any)=>sumNum+=item2[item1.item.name])
                return{orderCode:item1.value,scjhh:item1.value,sumNum:sumNum,childDetail:item1.item.tbData};
            })
        }
    }
    //计算选择的子订单总数量
    public calSelSumNum():any{
        let sumNum:number=0;
        if(this.dataObj.otherParams.orderSplitFlag==1){//里面其实只有一个订单对象
            sumNum=this.dataObj.otherParams.selectOrders[0].item.sumNum;
        }else{//里面是所有的子订单对象
            this.dataObj.otherParams.selectOrders.forEach((item1:any)=>{
                item1.item.tbData.forEach((item2:any)=>sumNum+=item2[item1.item.name])
            });
        }
        return sumNum;
    }
    //子订单合并组完成之后，需要处理子订单下拉数据集
    public afterGroup():void{
        //从子订单下拉数据集中去除本次选择的子订单
        this.dataObj.otherParams.childOrders=this.dataObj.otherParams.childOrders.filter((item:any)=>!this.dataObj.otherParams.selectOrders.some((item2:any)=>item2.value===item.value));
        //置空本次选择的子订单，否则子订单下拉选择框会有值显示，但是下拉数据集中又没有这些选项了（因为上一步已经清空了）
        this.dataObj.otherParams.selectOrders=[];
        //关闭弹出框
        this.dataObj.otherParams.groupDialog.visible=false;
    }
}