import XyzYhrzCard from './XyzYhrzCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
import XyzYhrzListUtil,{IXyzYhrzListDataObj} from './xyzYhrzListUtil';
import language from './xyzYhrzLanguage'
import XyzCard from "@/views/project/order/finance/xyz/xyz/XyzCard.vue";
export default defineComponent ({
    name: 'XyzYhrzList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:IXyzYhrzListDataObj=reactive<IXyzYhrzListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                queryParam: {},
                modelComp: XyzYhrzCard,
                modelMethod: utils.OrderProviderApi.buildUrl('/xyzYhrz/pageData')
            },
            otherParams:{},
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new XyzYhrzListUtil(proxy,dataObj);
        })
        onMounted(()=>{
        })
        //---------------------------computed------------

        const formatPageInfo=(options:any)=>{
            //找到需要格式化的列
            return options;
        }
        //查看信用证卡片
        const openXyzCard=async (id:string)=>{
            await utils.UtilPub.openDialog({proxy:proxy,addOrEdit:'edit',id:id,modelComp:XyzCard,ownerComp:proxy,cardFrom:'xyzYhrzList',
                title:'信用证详情',contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true})
        }
        return{
            ...toRefs(dataObj),formatPageInfo,openXyzCard
        }
    }
});